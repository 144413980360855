<template>
  <v-dialog
    v-model="dialog"
    :max-width="width"
  >
    <v-card class="pa-4">
      <v-card-title class="headline mb-6 pt-1 justify-center">
        {{ title }}
      </v-card-title>
      <v-card-actions class="justify-space-between">
        <v-btn
          color="primary"
          @click="disagree"
        >
          Cancel
        </v-btn>
        <v-btn
          color="error"
          @click="agree"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Are you sure?'
    },
    width: {
      type: Number,
      default: 420
    }
  },
  methods: {
    agree() {
      this.$emit('agree')
    },
    disagree() {
      this.$emit('disagree')
    }
  }
}
</script>
