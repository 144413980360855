<template>
  <dashboard-content full-width>
    <v-row v-if="item">
      <v-col class="col-12 py-0">
        <h1 class="mb-4">
          Reminder
        </h1>

        <v-row>
          <v-col class="col-12">
            <v-row justify="space-between">
              <v-col class="col-auto">
                <v-btn
                  link
                  :to="{ name: 'summary-reminders' }"
                  color="primary"
                >
                  <v-icon class="mr-2">
                    {{ mdiChevronLeft }}
                  </v-icon> Back
                </v-btn>
              </v-col>
              <v-col class="col-auto">
                <v-btn
                  color="primary"
                  class="mx-1"
                  @click="chooseNextAppointmentDate"
                >
                  <v-icon class="mr-2">
                    {{ mdiSend }}
                  </v-icon> Send now
                </v-btn>
                <v-btn
                  color="error"
                  @click.stop="confirmDialog = true"
                >
                  <v-icon class="mr-2">
                    {{ mdiClose }}
                  </v-icon> Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="col-12">
            <v-card>
              <v-card-text>
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  show-arrows
                >
                  <v-tab href="#general">
                    General info
                  </v-tab>
                  <v-tab href="#notifications">
                    Notifications
                  </v-tab>
                  <v-tab
                    v-if="isAdmin"
                    href="#logs"
                  >
                    Logs
                  </v-tab>
                  <v-tab href="#rawobject">
                    Raw document
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    class="my-4"
                    value="general"
                  >
                    <tab-general :item="item" />
                  </v-tab-item>

                  <v-tab-item
                    class="my-4"
                    value="notifications"
                  >
                    <tab-notifications :times="times" />
                  </v-tab-item>

                  <v-tab-item
                    v-if="isAdmin"
                    class="my-4"
                    value="logs"
                  >
                    <tab-logs :schedule-ref="scheduleRef" />
                  </v-tab-item>

                  <v-tab-item
                    class="my-4"
                    value="rawobject"
                  >
                    <pre>{{ item }}</pre>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-dialog
        v-model="nextAppointmentDialog"
        max-width="600"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-6 pt-1 justify-center">
            Choose next appointment
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="col-6">
                <v-select
                  v-model="nextAppointmentDate"
                  :items="nextAppointmentDates"
                  label="Next appointment dates"
                />
              </v-col>
              <v-col class="col-6">
                <v-select
                  v-model="nextAppointmentTime"
                  :items="nextAppointmentTimes"
                  label="Next appointment times"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-btn
              color="error"
              @click="nextAppointmentDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="sendNow"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <confirm-modal
        v-if="item"
        :dialog="confirmDialog"
        @agree="remove"
        @disagree="confirmDialog = false"
      />
    </v-row>
  </dashboard-content>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiChevronLeft, mdiClose, mdiSend } from '@mdi/js'
import DashboardContent from '@/components/dashboard/DashboardContent'
import ConfirmModal from '@/components/ConfirmModal'
import { TO_SEND } from '@/kit/dictionaries/timesSendStatus'

// Table General, Notification, and Logs not needed for now
// Copied from admin, specific uses unknown
// import TabGeneral from '@/views/dashboard/summary/reminders/TabGeneral'
// import TabNotifications from '@/views/dashboard/summary/reminders/TabNotifications'
// import TabLogs from '@/views/dashboard/summary/reminders/TabLogs'

export default {
  name: 'SummaryRemindersShow',
  components: {
    DashboardContent,
    // TabLogs,
    // TabNotifications,
    // TabGeneral,
    ConfirmModal
  },
  data() {
    return {
      mdiSend,
      mdiClose,
      mdiChevronLeft,
      tab: 'general',
      id: null,
      item: null,
      scheduleRef: null,
      times: [],
      nextAppointmentDate: null,
      nextAppointmentTime: null,
      confirmDialog: false,
      nextAppointmentDialog: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['isAdmin']),
    nextAppointmentDates() {
      return this.item.date || []
    },
    nextAppointmentTimes() {
      return this.item.time.map(item => this.formatTimeUtcToLocal(item)) || []
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.scheduleRef = this.$firebase
      .firestore()
      .collection('schedules')
      .doc(this.id)

    this.$bind('item', this.scheduleRef).then(() => {
      this.$bind(
        'times',
        this.$firebase
          .firestore()
          .collection('times')
          .where('schedule', '==', this.scheduleRef)
          .orderBy('sendTime', 'desc')
      )
    })
  },
  methods: {
    async remove() {
      this.loaded = false

      await this.$firebase
        .firestore()
        .collection('schedules')
        .doc(this.id)
        .delete()
        .then(() => {
          this.confirmDialog = false
          this.$notify({
            text: 'Successfully deleted'
          })
          this.$router.push({ name: 'summary-reminders' })
        })
        .catch(() => {
          this.confirmDialog = false
          this.$notify({
            type: 'err',
            text: 'Error occurred during delete'
          })
        })

      this.loaded = true
    },
    chooseNextAppointmentDate() {
      this.nextAppointmentDate = this.nextAppointmentDates[0]
      this.nextAppointmentTime = this.nextAppointmentTimes[0]
      this.nextAppointmentDialog = true
    },
    sendNow() {
      this.$firebase
        .firestore()
        .collection('times')
        .add({
          schedule: this.scheduleRef,
          sendTime: this.$firebase.firestore.Timestamp.now(),
          sendStatus: TO_SEND,
          nextAppointmentDate: this.nextAppointmentDate,
          nextAppointmentTime: this.nextAppointmentTime
        })
        .then(() => {
          this.$notify({
            text: 'Successfully scheduled'
          })
        })
        .finally(() => (this.nextAppointmentDialog = false))
    }
  }
}
</script>
